import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center horizontal"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/cf3f91178a38ea3f7557276240bd0a0f/aa440/Morne_interior_of_an_undersea_marketplace_inside_a_bathysphere__888bf3f7-1b2c-4491-a36c-318200a97c97.png",
            "srcSet": ["/static/cf3f91178a38ea3f7557276240bd0a0f/5ff7e/Morne_interior_of_an_undersea_marketplace_inside_a_bathysphere__888bf3f7-1b2c-4491-a36c-318200a97c97.png 375w", "/static/cf3f91178a38ea3f7557276240bd0a0f/1d69c/Morne_interior_of_an_undersea_marketplace_inside_a_bathysphere__888bf3f7-1b2c-4491-a36c-318200a97c97.png 750w", "/static/cf3f91178a38ea3f7557276240bd0a0f/aa440/Morne_interior_of_an_undersea_marketplace_inside_a_bathysphere__888bf3f7-1b2c-4491-a36c-318200a97c97.png 1500w", "/static/cf3f91178a38ea3f7557276240bd0a0f/71c1d/Morne_interior_of_an_undersea_marketplace_inside_a_bathysphere__888bf3f7-1b2c-4491-a36c-318200a97c97.png 1536w"],
            "width": "500px",
            "className": "right center horizontal"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`The Bathysward Market District`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`The `}<strong parentName="p">{`Bathysward`}</strong>{` is a significant outpost, embassy, and marketplace off the coast of `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`, housing a large population of `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Msanti`}</a>{` that interface with the city and `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudan Government`}</a>{`.`}</p>
    <h2>{`Construction`}</h2>
    <p>{`Construction of the Bathysward `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      